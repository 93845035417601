exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accueil-js": () => import("./../../../src/pages/accueil.js" /* webpackChunkName: "component---src-pages-accueil-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavage-automatique-js": () => import("./../../../src/pages/lavage-automatique.js" /* webpackChunkName: "component---src-pages-lavage-automatique-js" */),
  "component---src-pages-lavage-manuel-js": () => import("./../../../src/pages/lavage-manuel.js" /* webpackChunkName: "component---src-pages-lavage-manuel-js" */),
  "component---src-pages-lavage-pl-js": () => import("./../../../src/pages/lavage-pl.js" /* webpackChunkName: "component---src-pages-lavage-pl-js" */),
  "component---src-pages-laverie-js": () => import("./../../../src/pages/laverie.js" /* webpackChunkName: "component---src-pages-laverie-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-peripherique-exterieur-js": () => import("./../../../src/pages/peripherique-exterieur.js" /* webpackChunkName: "component---src-pages-peripherique-exterieur-js" */)
}

